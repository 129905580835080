import { ScannedCustomer } from "@/interfaces/customer";
import { fnsFormatDate, fnsParse } from "@/utils/date-fns.utils";

class ScannerService {
  protected fieldsMap: { [key: string]: string } = {
    DAQ: "id_number",
    DCS: "last_name",
    DAC: "first_name",
    DAD: "middle_name",
    DBC: "gender",
    DAG: "address",
    DAI: "city",
    DAJ: "state",
    DAK: "zip",
    DCG: "country",
    DBA: "id_expiration",
    DBB: "birthday"
  };
  protected keyPairsDelimiters = [
    { startLimit: "DAQ", endLimit: "DCS" },
    { startLimit: "DCS", endLimit: "DDE" },
    { startLimit: "DAC", endLimit: "DDF" },
    { startLimit: "DAD", endLimit: "DDG" },
    { startLimit: "DBC", endLimit: "DAU" },
    { startLimit: "DAG", endLimit: "DAH" },
    { startLimit: "DAI", endLimit: "DAJ" },
    { startLimit: "DAJ", endLimit: "DAK" },
    { startLimit: "DAK", endLimit: "DCF" },
    { startLimit: "DCG", endLimit: "DAW" },
    { startLimit: "DBA", endLimit: "DBB" },
    { startLimit: "DBB", endLimit: "DBA" }
  ];
  /**
   * Parse data to match Customer values
   * @param {string} scanResult What the scanner reads from the ID (raw)
   */
  public parseScan(scanResult: string): ScannedCustomer {
    const customerData: { [key: string]: string } = {};

    for (const pair of this.keyPairsDelimiters) {
      let regx = `${pair.startLimit}.*(?=${pair.endLimit})`;
      let res = scanResult.match(new RegExp(regx, "g"));
      if (!res || res === null) {
        // condition for id_number
        if (pair.endLimit === "DCS") {
          regx = `${pair.startLimit}.*(?=DDK)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for last_name
        if (pair.endLimit === "DDE") {
          regx = `${pair.startLimit}.*(?=DAC)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for first_name
        if (pair.endLimit === "DDF") {
          regx = `${pair.startLimit}.*(?=DAD)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for middle_name
        if (pair.endLimit === "DDG") {
          regx = `${pair.startLimit}.*(?=DCU)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for gender
        if (pair.endLimit === "DAU") {
          regx = `${pair.startLimit}.*(?=DAQ)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for address
        if (pair.endLimit === "DAH") {
          regx = `${pair.startLimit}.*(?=DAI)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for country
        if (pair.endLimit === "DAW") {
          regx = `${pair.startLimit}.*(?=DCK)`;
          res = scanResult.match(new RegExp(regx, "g"));
          if (!res || res === null) {
            regx = `${pair.startLimit}.*(?=DCF)`;
            res = scanResult.match(new RegExp(regx, "g"));
          }
        }
        // condition for id_expiration
        if (pair.endLimit === "DBB") {
          regx = `${pair.startLimit}.*(?=DBD)`;
          res = scanResult.match(new RegExp(regx, "g"));

          if (!res || res === null) {
            regx = `${pair.startLimit}.*(?=DBC)`;
            res = scanResult.match(new RegExp(regx, "g"));
          }
        }
      } else {
        // condition for first_name
        if (pair.endLimit === "DDF" && res![0].length > 15) {
          regx = `${pair.startLimit}.*(?=DAD)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for middle_name
        if (pair.endLimit === "DDG" && res![0].length > 15) {
          regx = `${pair.startLimit}.*(?=DCU)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
        // condition for last_name
        if (pair.endLimit === "DDE" && res![0].length > 15) {
          regx = `${pair.startLimit}.*(?=DAC)`;
          res = scanResult.match(new RegExp(regx, "g"));
        }
      }
      if (res && res.length) {
        customerData[this.fieldsMap[pair.startLimit]] = res[0]
          .replace(pair.startLimit, "")
          .trim();
      }
    }

    if (customerData.birthday) {
      customerData.birthday = this.parseDate(customerData.birthday);
    }
    if (customerData.country.length > 3) {
      customerData.country = customerData.country.slice(0, 3);
    }

    if (customerData.id_expiration) {
      customerData.id_expiration = this.parseDate(customerData.id_expiration);
    }
    if (customerData.zip) {
      customerData.zip = customerData.zip.slice(0, 5);
    }

    return customerData as ScannedCustomer;
  }
  // Parsing Date only for US standard
  protected parseDate(date: string): string {
    return fnsFormatDate(fnsParse(date, "MMddyyyy"));
  }
}
export const scannerService = new ScannerService();
