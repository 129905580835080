import { currencyFilter } from "@/filters/currency.filter";
import { Customer } from "@/interfaces/customer";
import { JournalNotes } from "@/interfaces/journalNotes";
import {
  LoyaltyPoint,
  LoyaltyProgram,
  LoyaltyProgramTier
} from "@/interfaces/loyaltyProgram";
// import { customerService } from "@/services/customer.service";
import { RetailSettings } from "@/interfaces/retailSettings";
import { customerService } from "@/services/customer.service";
import {
  TableLimitComponent,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./TableCustomerDetails.template.vue";

// import VueObserveVisibility from "vue-observe-visibility";

// Vue.use(VueObserveVisibility);
@Component({
  mixins: [Template],
  components: {
    TableLimitComponent
  },
  filters: {
    currencyFilter
  }
})
export default class TableCustomerDetailsComponent extends Vue {
  @Prop({ required: true })
  public item!: Customer;
  @Prop({ required: true })
  public loyaltyRewards!: LoyaltyProgram;
  public amountStrings: { [key: string]: TranslateResult } = {};
  @Prop({ required: true })
  public loading!: boolean;
  @Prop({ required: true })
  public loyaltyPoint!: LoyaltyPoint;
  @Prop({ required: true })
  public journalNotes!: JournalNotes[];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("scrollPagination", { namespace: "CustomerModule" })
  public scrollPagination!: TablePagination;
  public isLoading = false;

  public getMember() {
    return (
      (this.item.member_level && this.item.member_level.name) ||
      this.$t("member_not")
    );
  }
  public get customerAmountPoint() {
    return this.$options.filters!.currency(
      (this.loyaltyRewards!.total_points!.LOYALTY_POINTS *
        this.loyaltyPoint!.redemption_value_dollars!) /
        this.loyaltyPoint!.redemption_value_per_points!
    );
  }
  public availablePrograms(programs: LoyaltyProgram[]) {
    return programs.filter(program => this.getCurrentRewards(program)!.length);
  }

  public getCurrentRewards(program: LoyaltyProgram) {
    if (!program.program_tiers) {
      return;
    }
    let programTiers: LoyaltyProgramTier[];
    if (!Array.isArray(program.program_tiers)) {
      const allKeysNumbers = Object.keys(program.program_tiers).every(
        key => !Number.isNaN(parseInt(key, 10))
      );
      if (allKeysNumbers) {
        programTiers = Object.values(program.program_tiers);
      } else {
        return;
      }
    } else {
      programTiers = program.program_tiers;
    }

    return programTiers.filter((tier: LoyaltyProgramTier) => {
      return tier.apply;
    });
  }
  public statusCheckIn(item: Customer) {
    return (
      !item.online_pending &&
      !item.online_filled &&
      !item.order_saved &&
      !item.call_in_pending &&
      !item.call_in_filled &&
      !item.in_store_pending &&
      !item.in_store_filled
    );
  }

  public async checkInScrollDownApi() {
    const listElm = this.$refs.checkInNotesScroll as HTMLElement;

    if (
      listElm &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      this.isLoading = true;
      if (
        this.scrollPagination.lastPage !== this.scrollPagination.currentPage
      ) {
        const response = await customerService.getJournalsPopUpModel(
          this.item.customer_id!,
          {
            currentPage: this.scrollPagination.currentPage + 1,
            itemsPerPage: 10,
            totalItems: 0,
            itemsPerPageOptions: [],
            from: 0,
            to: 0
          },
          ["POPUP_MODAL_NOTE_FLAG", "SHOW_NOTE_FLAG "]
        );

        this.journalNotes = [...this.journalNotes, ...response.data];
      }

      this.isLoading = false;
    }
  }

  public mounted() {
    this.amountStrings = {
      NUMBER_OF_VISITS: this.$t("customer.visits"),
      LOYALTY_POINTS: this.$t("customer.points")
    };
  }
}
