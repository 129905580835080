import FiltersCheckInComponent from "@/components/retail/checkIn/filtersCheckIn/FiltersCheckIn.component";
import AddCustomerComponent from "@/components/retail/inStoreCustomers/AddCustomer/AddCustomer.component";
import InStoreCustomersComponent from "@/components/retail/inStoreCustomers/InStoreCustomers.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { JournalNotes } from "@/interfaces/journalNotes";
import { LoyaltyPoint, LoyaltyProgram } from "@/interfaces/loyaltyProgram";
import { BiotrackTraceNotification } from "@/interfaces/notification";
import { RetailSettings } from "@/interfaces/retailSettings";
import { pusherEvents, store } from "@/internal";
import { CustomerCheckInTableMetadata } from "@/metadata/customer";
import { customerActionService } from "@/services/customer.action.service";
import { customerService } from "@/services/customer.service";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  BooleanCheck,
  ExpandAction,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import TableCustomerDetailsComponent from "../customers/customersList/tableCustomerDetails/TableCustomerDetailsComponent";
import Template from "./CheckIn.template.vue";
import IdScannerComponent from "./idScanner/idScanner.component";
const namespace: string = "CustomerModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableCustomerDetailsComponent,
    InStoreCustomersComponent,
    FiltersCheckInComponent,
    AddCustomerComponent
  },
  inject: ["$changes"]
})
export default class CheckInComponent extends Vue {
  get getCustomers() {
    this.customers.map(data => {
      if (data.customer_journals_count) {
        data.notes = String(data.customer_journals_count);
        data.shows_checkin_note = true;
      } else {
        data.notes = null;
        data.shows_checkin_note = false;
      }
      data.limits = data.limits!.filter(objects => typeof objects === "object");
    });
    return this.customers.reverse();
  }
  @Getter("customers", { namespace })
  public customers!: Customer[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Action("loadCustomers", { namespace })
  public getCustomersAction!: Callback;
  @Action("deleteCustomer", { namespace })
  public deleteCustomersAction!: Callback;
  @Action("addCustomer", { namespace })
  public addCustomerAction!: Callback;
  @Action("filterCustomers", { namespace })
  public filterCustomers!: Callback;
  public preLoading: boolean = true;
  @Action("searchToCustomerList", { namespace })
  public searchCustomerAction!: Callback;
  @Action("configService", { namespace })
  public configService!: Callback;
  @Action("clearCustomerList", { namespace })
  public clearCustomerList!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("setCustomer", { namespace: "CustomerModule" })
  public setCustomer!: Callback;

  public showNewCheckin: boolean = true;
  public journalNotes!: JournalNotes[];
  public showFilters: boolean = false;
  public createModifyCustomerPermission = false;
  public generalActions: ActionSubheader[] = customerActionService.getGeneralActions(
    this.newCheckin,
    this.openFilters,
    this.openIdScanner
  );
  public rowActions: TableAction[] = [];
  public headers: TableHeader[] = CustomerCheckInTableMetadata;
  public paginate = customerService.paginationAction();
  public rowLoading = false;
  public loyaltyRewards: LoyaltyProgram | null = null;
  public loyaltyPoint: LoyaltyPoint | null = null;
  public loyaltyRewardsStorage: any[] = [];
  public loyaltyPointStorage: any[] = [];
  public journalNotesStorage: any[] = [];
  public currentRow?: number;
  public modalOpen = false;
  public filteresSelected = [];

  public filter(header: TableHeader) {
    const h = { ...header };
    if (h.value === "first_name/j/last_name") {
      h.value = "first_name";
    }

    if (h.value.includes("serving_number.number")) {
      h.value = "serving_number.number";
    }

    if (h.value.includes("serving_number.status")) {
      h.value = "serving_number.status";
    }

    if (h.value.includes("time_in_store")) {
      h.value = "serving_number.created_at";
    }

    customerService.sortQuery(h);
  }

  public openFilters() {
    this.showFilters = !this.showFilters;
  }
  // Added Filters query in pagination
  public storeFilterSelected(data: []) {
    this.filteresSelected = data;
  }

  public openIdScanner() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      this.$modals
        .load(IdScannerComponent, {
          size: "fit",
          positionY: "center",
          title: "SCAN"
        })
        .then(() => {
          this.getCustomersAction();
        })
        .catch(() => {
          // Nothing to do on close
        })
        .finally(() => (this.modalOpen = false));
    }
  }

  public async onExpand(event: ExpandAction) {
    if (this.requiresRowUpdate(event.index)) {
      this.rowLoading = true;
      this.loyaltyPoint = await loyaltyProgramService.getLoyaltyPoints();
      this.loyaltyRewards = await customerService.getLoyaltyRewards(
        String(event.item)
      );
      await customerService
        .getJournalsPopUpModel(
          event.item,
          {
            totalItems: 0,
            itemsPerPage: 10,
            itemsPerPageOptions: [5, 10, 20, 50],
            currentPage: 1,
            lastPage: 0,
            from: 1,
            to: 10
          },
          ["POPUP_MODAL_NOTE_FLAG", "SHOW_NOTE_FLAG "]
        )
        .then(result => (this.journalNotes = result.data));
      this.persistData(event.index);
      this.currentRow = event.index;
      this.rowLoading = false;
    } else {
      this.loyaltyPoint = { ...this.loyaltyPointStorage[event.index] };
      this.loyaltyRewards = { ...this.loyaltyRewardsStorage[event.index] };
      this.journalNotes = Object.values(this.journalNotesStorage[event.index]);
    }
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      filteresSelected: this.filteresSelected,
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadCustomers`
    });
  }

  public async renderTraceabilityModal(
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string,
    customerJournalNotes: JournalNotes[],
    id: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      {
        size: "normal",
        positionX: "center",
        positionY: "center",
        // @ts-ignore
        style: "max-height:680px"
      },
      {
        modalData: {
          justifyContent: "justify-start",
          status: {
            name: statusName,
            style: "fontSize:22px ; fontWeight:600;"
          },

          description: {
            name: descriptionName,
            style: "fontSize:20px ; textAlign:left"
          },
          title: {
            name: this.$t("biotrack_traceability.retail_customer_note_title"),
            style: "fontSize:27px ; letter-spacing: 0px;"
          },
          titleAvatar: {
            name: "/img/icon_primary_menu_retail@2x.009e06e8.png",
            size: 80
          },
          loading: this.$t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue,
          customerJournalNotes,
          id
        }
      }
    );
  }

  public beforeDestroy() {
    this.setCustomer(null);
  }

  protected requiresRowUpdate(index: number) {
    return (
      this.currentRow !== index &&
      !this.loyaltyPointStorage[index] &&
      !this.loyaltyRewardsStorage[index] &&
      !this.journalNotesStorage[index]
    );
  }

  protected persistData(index: number) {
    this.loyaltyRewardsStorage = {
      ...this.loyaltyRewardsStorage,
      [index]: {
        ...this.loyaltyRewards
      }
    };
    this.loyaltyPointStorage = {
      ...this.loyaltyPointStorage,
      [index]: {
        ...this.loyaltyPoint
      }
    };
    this.journalNotesStorage = {
      ...this.journalNotesStorage,
      [index]: [...this.journalNotes]
    };
  }

  protected newCheckin() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      this.$modals
        .load(AddCustomerComponent, {
          size: "fit"
        })
        .catch(() => {
          // Nothing to do on close
        })
        .finally(() => (this.modalOpen = false));
    }
  }

  protected created() {
    this.clearCustomerList();
    this.$changes.watch([pusherEvents.customer], this.getCustomersAction);
    this.headers.forEach((el: TableHeader) => {
      if (el.fieldComponent === "TableLimitComponent") {
        el.options = {
          showRemaining:
            this.retailSettings.sales_limit_view === "REMAINING" ? true : false
        };
      }
    });
  }

  protected async mounted() {
    customerService.resetPagination();
    this.setPageNav({
      title: "customer.check-in",
      isLoading: () => this.preLoading,
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.createModifyCustomerPermission = this.hasPermission(
      policyList.createModifyCustomers
    );
    this.rowActions = customerActionService.getCheckInRowActions(
      this.createModifyCustomerPermission
    );
    this.configService("check-in");
    await customerService.filterInStore();
    this.showNewCheckin = !!this.customers.length;
    // using customerJournalNotes as static data once BE API ready will change accordingly

    if (this.$route.params.customerId) {
      const response = await customerService.getJournalsPopUpModel(
        this.$route.params.customerId!,
        {
          currentPage: 1,
          itemsPerPage: 10,
          totalItems: 0,
          itemsPerPageOptions: [],
          from: 0,
          to: 0
        },
        ["POPUP_MODAL_NOTE_FLAG"]
      );

      const customerJournalNotes: JournalNotes[] = response.data.filter(
        (singleNote: JournalNotes) =>
          singleNote.priority === "POPUP_MODAL_NOTE_FLAG"
      );

      if (customerJournalNotes.length) {
        customerJournalNotes.map(singleNote => {
          (singleNote.status = {
            name: singleNote.title,
            style: "fontSize:22px ; fontWeight:600;"
          }),
            (singleNote.description = {
              name: singleNote.note,
              style: "fontSize:20px ; textAlign:left"
            });
        });
        await this.renderTraceabilityModal(
          "",
          "",
          "",
          true,
          false,
          "ok",
          customerJournalNotes,
          this.$route.params.customerId
        );
      }
    }
    this.preLoading = false;
  }
}
