import AddCustomerComponent from "@/components/retail/inStoreCustomers/AddCustomer/AddCustomer.component";
import { Component, Vue } from "vue-property-decorator";
import IdScannerComponent from "../checkIn/idScanner/idScanner.component";
import Template from "./InStoreCustomers.template.vue";

@Component({
  mixins: [Template],
  components: {
    AddCustomerComponent
  }
})
export default class InStoreCustomersComponent extends Vue {
  public isCustomerSearch: boolean = true;
  public modalOpen = false;

  public modalToggle() {
    this.$modals.load(AddCustomerComponent, {
      size: "fit"
    });
  }

  public openIdScanner() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      this.$modals
        .load(IdScannerComponent, {
          size: "fit",
          positionY: "center",
          title: "SCAN"
        })
        .then(
          () => {
            this.$emit("customersAdded");
          },
          () => {
            // Nothing to do on close
          }
        )
        .finally(() => (this.modalOpen = false));
    }
  }

  protected goTo(namedRoute: string): void {
    this.$router.push({ name: namedRoute });
  }
}
