import {
  Customer,
  defaultCustomer,
  ScannedCustomer
} from "@/interfaces/customer";
import { Location } from "@/interfaces/location";
import { customerService } from "@/services/customer.service";
import { scannerService } from "@/services/scanner.service";
import { CallbackPromise } from "helix-vue-components";
import hotkeys from "hotkeys-js";
import debounce from "lodash/debounce";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./idScanner.template.vue";

@Component({
  mixins: [Template]
})
export default class IdScannerComponent extends Vue {
  @Action("saveRecreationalCustomer", { namespace: "CustomerModule" })
  public saveCustomer!: CallbackPromise<Customer>;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public currentDecode: ScannedCustomer | null = null;
  public isCheckedIn: Customer | null = null;
  public isNewCustomer = false;
  public isScanning = false;
  public isInvalid = false;
  public isRecreational = false;
  protected sizeLast = 0;
  protected checkInCount = 0;
  protected scannerResults: string[] = [];
  protected checkInactivityD = debounce((context: this) => {
    context.processScan();
  }, 3000);

  public async onScan(data: string) {
    data = data.replace(/Enter/, "");
    if (data.includes("@")) {
      // New input
      this.scannerResults = [];
    }
    this.scannerResults.push(data);
    this.checkInactivityD(this);

    this.isScanning = true;
  }

  public registerCustomer() {
    this.$router.push({
      name: "customers-add",
      params: { newCustomer: JSON.stringify(this.currentDecode!) }
    });

    this.$emit("reject");
  }

  public closeModal() {
    if (this.checkInCount) {
      this.$emit("resolve");
    } else {
      this.$emit("reject");
    }
  }

  public resetModal() {
    this.isCheckedIn = null;
    this.currentDecode = null;
    this.isNewCustomer = false;
    this.isInvalid = false;
    this.init();
  }

  public async guestCheckin() {
    if (this.currentDecode) {
      const customer: Customer = {
        ...defaultCustomer,
        first_name: "RECREATIONAL",
        last_name: "CUSTOMER",
        type: "ANONYMOUS",
        birthday: this.currentDecode!.birthday
      };

      this.isCheckedIn = await this.saveCustomer({
        customer,
        reloadCustomers: false
      });
      if (this.isCheckedIn) {
        this.currentDecode = null;
        this.isNewCustomer = false;
        this.checkInCount++;
      } else {
        this.closeModal();
      }
    }
  }

  protected async processScan() {
    this.removeListener();
    this.currentDecode = scannerService.parseScan(this.scannerResults.join(""));
    let customerStore: Customer | null = null;
    if (this.currentDecode.id_number) {
      customerStore = await customerService.findByLicense(
        this.currentDecode.id_number
      );
    } else {
      this.isInvalid = true;
      this.isScanning = false;
      return;
    }
    // Check in customer
    if (customerStore) {
      const checkedIn = await customerService.checkInCustomer(
        customerStore.customer_id!
      );
      if (checkedIn) {
        this.isCheckedIn = customerStore;
        this.currentDecode = null;
        this.checkInCount++;
      } else {
        this.closeModal();
      }
    } else {
      this.isCheckedIn = null;
      this.isNewCustomer = true;
    }
    this.scannerResults = [];
    this.isScanning = false;
  }

  protected init() {
    // @ts-ignore
    this.$barcodeScanner.init(this.onScan);
  }

  protected removeListener() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }

  protected mounted() {
    this.init();
    this.isRecreational =
      this.currentLocation.location_type === "RETAIL_RECREATIONAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";
    hotkeys(
      "command+*, command+option+*, control+*, control+shift+*",
      "modals",
      event => event.preventDefault()
    );
    hotkeys.setScope("modals");
  }

  protected beforeDestroy() {
    this.removeListener();
    hotkeys.deleteScope("modals");
  }
}
